import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync, err } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentOrder, RentPriceCheckOptions } from '@/shared/interfaces/rentOrder';
import { RentOrderInput, Void } from '@/shared/graphql/graphql';
import { ApiRentPriceCheck } from '@/shared/interfaces/api/ApiRentPriceCheck';

export class RentOrderService implements RentOrder {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async createOrder(order: RentOrderInput): ResultAsync<Void, IGraphQLError> {
    const createRentOrderMutation = graphql(`
      mutation createOrder($input: RentOrderInput!) {
        createRentOrder(input: $input) {
          void
        }
      }
    `);

    const response = await this.graphql.mutate(createRentOrderMutation, { input: order });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }

  async priceCheck(options: RentPriceCheckOptions): ResultAsync<ApiRentPriceCheck, IGraphQLError> {
    const priceCheckQuery = graphql(`
      query rentPriceCheck($products: [RentPriceCheckInput]!, $companyId: Uuid) {
        rentPriceCheck(products: $products, companyId: $companyId) {
          totals {
            price {
              amount
              amountInCents
              currency
            }
            discountPercentage
            discount {
              amount
              amountInCents
              currency
            }
            priceWithDiscount {
              amount
              amountInCents
              currency
            }
            taxPercentage
            tax {
              amount
              amountInCents
              currency
            }
            finalPrice {
              amount
              amountInCents
              currency
            }
          }
          productPrices {
            productId
            quantity
            billableDays
            totalDurationPrice {
              amount
              amountInCents
              currency
            }
          }
        }
      }
    `);

    const response = await this.graphql.query(priceCheckQuery, options);

    return ok(response.rentPriceCheck as ApiRentPriceCheck);
  }
}
