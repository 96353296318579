import { computed } from 'vue';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentProducts } from '@/shared/interfaces/rentProducts';
import { ApiRentProduct } from '@/shared/interfaces/api/ApiRentProduct';
import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { ChannelEnum } from '@/shared/graphql/graphql';

const DEFAULT_PAGE_SIZE = 20;

export class RentProductsService implements RentProducts {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getProducts(
    variables: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiRentProduct>, ViolationErrorMap> {
    const extendedVariables = computed(() => ({
      limit: DEFAULT_PAGE_SIZE,
      channel: ChannelEnum.Ep,
      ...variables,
    }));

    const getProductsQuery = graphql(`
      query getRentProducts(
        $limit: Int
        $page: Int
        $category: Uuid
        $branch: Uuid
        $rent: DateInterval
        $buffer: DateInterval
        $sort: RentProductSortEnum
        $channel: ChannelEnum
      ) {
        rentProducts(
          limit: $limit
          page: $page
          category: $category
          branch: $branch
          rent: $rent
          buffer: $buffer
          sort: $sort
          channel: $channel
        ) {
          items {
            id
            articleNumber
            name
            optionalLabel
            categories {
              id
              code
              name
              parent {
                id
                code
                name
                parent {
                  id
                  code
                  name
                  parent {
                    id
                    code
                    name
                  }
                }
              }
            }
            pricePerDay {
              amount
              amountInCents
              currency
            }
            priceTotal {
              amount
              amountInCents
              currency
            }
            availability {
              id
              branch
              available
            }
            images {
              type
              path
            }
          }
          meta {
            current
            itemsPerPage
            last
            totalItems
          }
        }
      }
    `);

    const response = await this.graphql.query(getProductsQuery, extendedVariables.value);

    return ok({
      items: response.rentProducts?.items,
      ...response.rentProducts?.meta,
    } as PaginatedResource<ApiRentProduct>);
  }

  async getProduct(id: string): ResultAsync<ApiRentProduct, IGraphQLError> {
    const getProductQuery = graphql(`
      query getRentProduct($rentProductId: Uuid!, $channel: ChannelEnum!) {
        rentProduct(id: $rentProductId, channel: $channel) {
          id
          articleNumber
          name
          optionalLabel
          description
          attributes {
            code
            label
            type
            value
          }
          categories {
            id
            code
            name
          }
          pricePerDay {
            amount
            amountInCents
            currency
          }
          images {
            type
            path
          }
        }
      }
    `);
    const response = await this.graphql.query(getProductQuery, {
      rentProductId: id,
      channel: ChannelEnum.Ep,
    });
    return ok(response.rentProduct as ApiRentProduct);
  }
}
