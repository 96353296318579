import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentBuffer, RentBufferOptions } from '@/shared/interfaces/rentBuffer';
import { StringDuration } from '@/pages/rentProductOverview/interfaces/searchParams';

export class RentBufferService implements RentBuffer {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getBuffer(options: RentBufferOptions): ResultAsync<StringDuration, IGraphQLError> {
    const getBufferQuery = graphql(`
      query getBuffer($productId: Uuid!, $rent: DateInterval!) {
        rentBuffer(productId: $productId, rent: $rent)
      }
    `);

    const response = await this.graphql.query(getBufferQuery, options, 'network-only');

    return ok(response.rentBuffer as StringDuration);
  }
}
